import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { NotificationsService } from './notifications.service';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { AlertController } from '@ionic/angular';
export interface User {
  name: string;
  role: string;
  permissions: string[];
}
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  subscription: Subscription;
  private currentUser: BehaviorSubject<any> = new BehaviorSubject(null);
  //private profileUser: BehaviorSubject<any> = new BehaviorSubject(null);
  public profileUser: any;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  profileRole: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  //profileUser : any;
  constructor(
    private http: HttpClient,
    public storageService: StorageService,
    private notification: NotificationsService,
    private router: Router,
    public apiService: ApiService,
    public alertController: AlertController,
  ) {
    this.loadUser();
  }

  async loadUser() {

    try {
      // Obtém o usuário salvo no armazenamento local
      this.profileUser = await this.storageService.getObject(environment.name);

      if (this.profileUser) {
        // Verifica se o usuário está ativo na API
        const updatedData: any = await this.apiService.getTenant('person/' + this.profileUser.idPerson);
        //        const updatedData: any = await this.apiService.getTenant('person/'+idPerson);
        // Atualiza os dados no armazenamento local com os recebidos da API
        await this.storageService.setObject(environment.name, {
          cargo: updatedData.cargo,
          email: updatedData.email,
          foto: updatedData.foto,
          idPerson: updatedData.idPerson,
          name: updatedData.name,
          profile: updatedData.profile,
          role: updatedData.role,
          tenant_id: updatedData.tenant_id,
          token: updatedData.token,
          uuid: updatedData.uuid,
        });
        console.log("Atualizou usuario");

        // Atualiza os estados no serviço de autenticação
        this.profileUser = updatedData;
        this.isAuthenticated.next(true);
        this.currentUser.next(this.profileUser);

        if (Capacitor.getPlatform() !== 'web') {
          this.notification.registerTopic(this.profileUser.profile);
        } else {
          // Adicione lógica para web, se necessário
        }

      } else {
        // Nenhum usuário encontrado no armazenamento local
        this.isAuthenticated.next(false);
        this.currentUser.next(null);
      }
    } catch (error) {
      console.error('Erro ao carregar usuário:', error);
      this.isAuthenticated.next(false);
      this.currentUser.next(null);
    }
  }

  async setLogin(item) {
    try {
      const response: any = await this.apiService.getTenant('person/' + item.idPerson);
      console.log("checa usuario", response);
      if (response?.isActive) {
        // Cliente ativo: salva no armazenamento local
        await this.storageService.setObject(environment.name, item);
        this.profileUser = await this.storageService.getObject(environment.name);

        this.isAuthenticated.next(true);
        this.currentUser.next(this.profileUser);

        // Redireciona para a página inicial
        this.router.navigateByUrl('/home', { replaceUrl: true });

        // Configura notificações, se aplicável
        if (Capacitor.getPlatform() !== 'web') {
          this.notification.registerTopic(this.profileUser.profile);
        }
      } else {
        // Cliente inativo: impede o login
        console.log('Cliente inativo. Login não permitido.');
        this.isAuthenticated.next(false);
        this.router.navigateByUrl('/login', { replaceUrl: true });
      }

    } catch (error) {
      console.error('Erro ao verificar cliente ativo:', error);
      this.isAuthenticated.next(false);
      this.router.navigateByUrl('/login', { replaceUrl: true });
    }



    const setLogin = await this.storageService.setObject(environment.name, item);
    this.profileUser = await this.storageService.getObject(environment.name);
    console.log(this.profileUser);

    this.isAuthenticated.next(true);


    this.currentUser.next(this.profileUser);



    this.router.navigateByUrl('/home', { replaceUrl: true });

    if (Capacitor.getPlatform() !== 'web') {
      this.notification.registerTopic(this.profileUser.profile);
    } else {
      //  this.registerFire();
    }
  }
  isLoggedIn(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  async getProfile() {
    // console.log(this.profileUser);
    return this.profileUser;

  }
  getUser() {
    return this.currentUser.asObservable();
  }

  hasPermission(permissions: string[]): boolean {

    // console.log("Current User: ",this.currentUser.value.profile);

    //  for (let permission = 0; permission <= permissions.length; permission++) {

    if (permissions == this.currentUser.value.profile) {
      return true
    } else {
      console.log("Sem acesso");
      return false;
    }
    // }
  }
  async Alert(texto = null, header = null) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: texto,
      buttons: [
        {
          text: 'ok',
          handler: () => {
            //   console.log('Confirm Okay');
            // this.copyToken(texto);
          },
        },
      ],
    });
    await alert.present();
  }
  async logout() {

    const user = await this.storageService.getObject(environment.name);
    this.isAuthenticated.next(false);
    this.storageService.remove(environment.name);
    this.profileUser = null;
    //this.notification.UnregisterTopic(user.profile);
    this.storageService.remove('token');
  }
}
